import {AdminPictureApi, Configuration, PictureMetaData} from "../generated-sources";
import {TOKEN} from "../config/Constants";
import createConfiguration from "./TokenHelper";

const BASE_PATH = process.env.REACT_APP_BASEURL ? process.env.REACT_APP_BASEURL : ""

interface Provided<T> {
    data: T,
    total: number
    validUntil?: Date
}

class PictureService {

    private api(): AdminPictureApi {
        return new AdminPictureApi(createConfiguration());
    }

    allPictures(props: any): Promise<any> {
        console.log("props for allPictures: ", props)
        return this.api().pictures({
            pictureParameters: props
        }).then(picturesResponse => {
            const provided: Provided<Array<PictureMetaData>> = {
                data: picturesResponse.pictures,
                total: picturesResponse.total
            }
            return provided
        })

    }

    getOnePicture(pictureFilename: string): Promise<Provided<PictureMetaData>> {
        return this.api().onePicture({pictureFilename: pictureFilename}).then(p => {
            const ret: Provided<PictureMetaData> = {
                data: p,
                total: 1
            }
            return ret
        })
    }

    ratePicture(pictureFilename: string, rate: number): Promise<Provided<PictureMetaData>> {
        return this.api().ratePicture({
            pictureFilename: pictureFilename,
            updatePictureRating: {
                rating: rate
            }
        }).then(p => {
                const ret: Provided<PictureMetaData> = {
                    data: p,
                    total: 1
                }
                return ret
            }
        )
    }

    deleteOnePicture(pictureId: number): Promise<any> {
        return this.api().deletePicture({
            pictureId: pictureId
        }).then(picture => {
                return {
                    data: {
                        id: picture.id
                    }
                }
            }
        )
    }

    /*
        updateOneCat(catId: number, updates: CreateCat): Promise<Cat> {
            return this.api().updateCat({
                catid: catId,
                createCat: updates
            })
        }

        deleteOneCat(catId: number): Promise<Cat> {
            return this.api().deleteCat({catid: catId})
        }
    */

}


const pictureService: PictureService = new PictureService();
export default pictureService
