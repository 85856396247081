import React from 'react'
import {Button} from 'react-admin';
import HelpIcon from '@material-ui/icons/Help';
import RatingHelpDialog from "./RatingHelpDialog";
import {useState} from 'react'

const RatingHelpButton = () => {
    const [dialogOpen, setDialogOpen] = useState(false);
    return (
        <>
            <Button color="primary" onClick={() => setDialogOpen(true)}>
                <HelpIcon/>
            </Button>
            <RatingHelpDialog open={dialogOpen} onClose={() => setDialogOpen(false)}/>
        </>
    )
}

export default RatingHelpButton;