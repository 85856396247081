import React, {RefObject, useEffect} from 'react'
import {Dimension, PictureSummary} from "../../generated-sources/models";
import Webcam from 'react-webcam'
import {SummaryForCat} from "./SummaryForCat";
import AnnouncementIcon from '@material-ui/icons/Announcement';
import {browserName} from "react-device-detect";


const FACING_MODE_USER = "user";
const FACING_MODE_ENVIRONMENT = "environment";
const CAM_VIEW_NORMAL = "PhotoMode";
const CAM_VIEW_SHOOTING = "PhotoMode flash";
const CAM_VIEW_ILLEGAL = "PhotoMode illegal"

const desiredDimension: Dimension = {
    width: 375,
    height: 667
}

const videoConstraints = {
    width: 667,
    height: 375,
    facing: FACING_MODE_ENVIRONMENT
};



const CatCamera= (props: { pictureHandler: any, catName: string, pictureSummary: PictureSummary }) => {

    const [facingMode, setFacingMode] = React.useState(FACING_MODE_ENVIRONMENT);
    const [camViewClass, setCamViewClass] = React.useState(CAM_VIEW_NORMAL);
    const webcamRef: RefObject<any> = React.useRef<Webcam>(null);

    const capture = React.useCallback(
        () => {
            if (userHasNotAccepted()) {
                setCamViewClass(CAM_VIEW_ILLEGAL)
                normalizeCamera()
                return
            }

            const imageSrc = webcamRef.current?.getScreenshot();
            console.log("webcamRef: ", webcamRef.current)
            props.pictureHandler(imageSrc)
            setCamViewClass(CAM_VIEW_SHOOTING);
            setTimeout(() => {
                setCamViewClass(CAM_VIEW_NORMAL)
            }, 200)

        },
        [webcamRef]
    );
    // Might seem strange that we use the effect to set facing mode.
    // But iPhone (8 at least) only uses the user camera otherwise.
    // We always want the environment camera 😇
    useEffect(() => {
        setFacingMode(FACING_MODE_ENVIRONMENT)
    })

    const normalizeCamera = () => setTimeout(() => {
        setCamViewClass(CAM_VIEW_NORMAL)
    }, 200)

    const userHasNotAccepted = () => !webcamRef.current?.state.hasUserMedia

    return (
        <div className="ImageView">
            <Webcam
                audio={false}
                ref={webcamRef}
                screenshotFormat="image/jpeg"
                width={desiredDimension.width}
                height={desiredDimension.height}
                videoConstraints={{
                    ...videoConstraints,
                    facingMode
                }}
                onClick={capture}
            />
            {userHasNotAccepted() && (<> <div className="notification is-warning">
                <AnnouncementIcon className="verticalbottom"/> &nbsp;
                <p>Den här sidan är beroende av att kunna använda mobilens kamera. Se till att sidan har behörighet för kamera i din webläsare ({browserName})!</p>
                </div></>)}
            <SummaryForCat capture={capture} catName={props.catName} pictureSummary={props.pictureSummary}  camViewClass={camViewClass}/>
        </div>
    );
};

export default CatCamera;