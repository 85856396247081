/* tslint:disable */
/* eslint-disable */
/**
 * Hasseltass API
 * To search for cats from given image.
 *
 * The version of the OpenAPI document: 2.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Cat,
    CatFromJSON,
    CatFromJSONTyped,
    CatToJSON,
    PictureSummary,
    PictureSummaryFromJSON,
    PictureSummaryFromJSONTyped,
    PictureSummaryToJSON,
    User,
    UserFromJSON,
    UserFromJSONTyped,
    UserToJSON,
} from './';

/**
 * 
 * @export
 * @interface CatWithSummary
 */
export interface CatWithSummary {
    /**
     * 
     * @type {Cat}
     * @memberof CatWithSummary
     */
    cat: Cat;
    /**
     * 
     * @type {PictureSummary}
     * @memberof CatWithSummary
     */
    summary: PictureSummary;
    /**
     * 
     * @type {User}
     * @memberof CatWithSummary
     */
    owner: User;
}

export function CatWithSummaryFromJSON(json: any): CatWithSummary {
    return CatWithSummaryFromJSONTyped(json, false);
}

export function CatWithSummaryFromJSONTyped(json: any, ignoreDiscriminator: boolean): CatWithSummary {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'cat': CatFromJSON(json['cat']),
        'summary': PictureSummaryFromJSON(json['summary']),
        'owner': UserFromJSON(json['owner']),
    };
}

export function CatWithSummaryToJSON(value?: CatWithSummary | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'cat': CatToJSON(value.cat),
        'summary': PictureSummaryToJSON(value.summary),
        'owner': UserToJSON(value.owner),
    };
}


