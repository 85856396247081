/* tslint:disable */
/* eslint-disable */
/**
 * Hasseltass API
 * To search for cats from given image.
 *
 * The version of the OpenAPI document: 2.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface Sort
 */
export interface Sort {
    /**
     * 
     * @type {string}
     * @memberof Sort
     */
    field?: string;
    /**
     * 
     * @type {string}
     * @memberof Sort
     */
    order?: SortOrderEnum;
}

export function SortFromJSON(json: any): Sort {
    return SortFromJSONTyped(json, false);
}

export function SortFromJSONTyped(json: any, ignoreDiscriminator: boolean): Sort {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'field': !exists(json, 'field') ? undefined : json['field'],
        'order': !exists(json, 'order') ? undefined : json['order'],
    };
}

export function SortToJSON(value?: Sort | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'field': value.field,
        'order': value.order,
    };
}

/**
* @export
* @enum {string}
*/
export enum SortOrderEnum {
    ASC = 'ASC',
    DESC = 'DESC'
}


