/* tslint:disable */
/* eslint-disable */
/**
 * Hasseltass API
 * To search for cats from given image.
 *
 * The version of the OpenAPI document: 2.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    ApiErrorBody,
    ApiErrorBodyFromJSON,
    ApiErrorBodyToJSON,
    Cat,
    CatFromJSON,
    CatToJSON,
    CreateCat,
    CreateCatFromJSON,
    CreateCatToJSON,
} from '../models';

export interface CreateCatRequest {
    createCat: CreateCat;
}

export interface DeleteCatRequest {
    catid: number;
}

export interface OneCatRequest {
    catid: number;
}

export interface UpdateCatRequest {
    catid: number;
    createCat?: CreateCat;
}

/**
 * 
 */
export class AdminCatApi extends runtime.BaseAPI {

    /**
     * All cats that this user owns are returned.
     * Get all cats for logged in user.
     */
    async allCatsRaw(): Promise<runtime.ApiResponse<Array<Cat>>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/private/logged-in-user/cats`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(CatFromJSON));
    }

    /**
     * All cats that this user owns are returned.
     * Get all cats for logged in user.
     */
    async allCats(): Promise<Array<Cat>> {
        const response = await this.allCatsRaw();
        return await response.value();
    }

    /**
     * Creates a new cat for the given user if permitted.
     * Create one cat for given user
     */
    async createCatRaw(requestParameters: CreateCatRequest): Promise<runtime.ApiResponse<Cat>> {
        if (requestParameters.createCat === null || requestParameters.createCat === undefined) {
            throw new runtime.RequiredError('createCat','Required parameter requestParameters.createCat was null or undefined when calling createCat.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/private/logged-in-user/cats`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreateCatToJSON(requestParameters.createCat),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => CatFromJSON(jsonValue));
    }

    /**
     * Creates a new cat for the given user if permitted.
     * Create one cat for given user
     */
    async createCat(requestParameters: CreateCatRequest): Promise<Cat> {
        const response = await this.createCatRaw(requestParameters);
        return await response.value();
    }

    /**
     * Removes the cat given by id for given user if permitted.
     * Deletes given cat for given user.
     */
    async deleteCatRaw(requestParameters: DeleteCatRequest): Promise<runtime.ApiResponse<Cat>> {
        if (requestParameters.catid === null || requestParameters.catid === undefined) {
            throw new runtime.RequiredError('catid','Required parameter requestParameters.catid was null or undefined when calling deleteCat.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/private/logged-in-user/cats/{catid}`.replace(`{${"catid"}}`, encodeURIComponent(String(requestParameters.catid))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => CatFromJSON(jsonValue));
    }

    /**
     * Removes the cat given by id for given user if permitted.
     * Deletes given cat for given user.
     */
    async deleteCat(requestParameters: DeleteCatRequest): Promise<Cat> {
        const response = await this.deleteCatRaw(requestParameters);
        return await response.value();
    }

    /**
     * The cat given by id for logged in user if permitted.
     * Get given cat for given logged in user.
     */
    async oneCatRaw(requestParameters: OneCatRequest): Promise<runtime.ApiResponse<Cat>> {
        if (requestParameters.catid === null || requestParameters.catid === undefined) {
            throw new runtime.RequiredError('catid','Required parameter requestParameters.catid was null or undefined when calling oneCat.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/private/logged-in-user/cats/{catid}`.replace(`{${"catid"}}`, encodeURIComponent(String(requestParameters.catid))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => CatFromJSON(jsonValue));
    }

    /**
     * The cat given by id for logged in user if permitted.
     * Get given cat for given logged in user.
     */
    async oneCat(requestParameters: OneCatRequest): Promise<Cat> {
        const response = await this.oneCatRaw(requestParameters);
        return await response.value();
    }

    /**
     * Updates the given cat for logged in user if permitted.
     * Updates given cat for logged in user.
     */
    async updateCatRaw(requestParameters: UpdateCatRequest): Promise<runtime.ApiResponse<Cat>> {
        if (requestParameters.catid === null || requestParameters.catid === undefined) {
            throw new runtime.RequiredError('catid','Required parameter requestParameters.catid was null or undefined when calling updateCat.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/private/logged-in-user/cats/{catid}`.replace(`{${"catid"}}`, encodeURIComponent(String(requestParameters.catid))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: CreateCatToJSON(requestParameters.createCat),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => CatFromJSON(jsonValue));
    }

    /**
     * Updates the given cat for logged in user if permitted.
     * Updates given cat for logged in user.
     */
    async updateCat(requestParameters: UpdateCatRequest): Promise<Cat> {
        const response = await this.updateCatRaw(requestParameters);
        return await response.value();
    }

}
