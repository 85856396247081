import React from 'react'
import {
    Create,
    SimpleForm,
    TextInput, useNotify, useRedirect
} from "react-admin";

const CreateCatTitle = ({record}: any) => {
    return <span>Create cat {record.name}</span>

}

const CatCreate = (props: any) => {
    console.log("creating cat ", props)
    const notify = useNotify();
    const redirect = useRedirect();
    return (
        <Create title={<CreateCatTitle/>} {...props}  >
            <SimpleForm >
                <TextInput required={true} label="Namn" source="name"/>
            </SimpleForm>
        </Create>)
};


export default CatCreate;
