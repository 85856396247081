import React from 'react'
import InfoIcon from '@material-ui/icons/Info';
import img from '../../media/training_picture_example.png'
import foundImg from '../media/found_cat.jpg'
import {PictureCategory} from "../../generated-sources/models";
import {SummaryForCat} from "./SummaryForCat";
import AnnouncementIcon from '@material-ui/icons/Announcement';
import "../../sass/bulma-settings.scss"
import "./StaticOwnerInformation.css"
import {HasseltassResource} from "../../provider/HasseltassProvider";
import Ratings from "react-ratings-declarative";
import RatingTip from "../components/RatingTip";

const StaticOwnerInformation = () => {
    return <>
        <h2 className="subtitle is-3">
            Pilotprojekt
        </h2>
        <div className="content">
            <p>Det här är fortfarande ett pilotprojekt. Du behövs för att testa funktionalitet och användarvänlighet.
                Framförallt är det intressant att se om den automatiska annoteringen (kattdetektionen) är tillräckligt
                bra.
                Du kommer definitivt att störa dig på delar av användargränssnittet, sån feedback är också välkommet men
                det är inte främsta fokus.</p>
            <p>
                Målet är att hitta katter som är på rymmen med hjälp av sin mobilkamera. Lösningsidén bygger på två
                delar:
                <ol>
                    <li>Ägare matar systemet med träningsdata genom att ta många kattbilder.</li>
                    <li>Utomstående hittar en misstänkt bortsprungen katt, fotograferar den och skickar in till
                        systemet,
                        om match finns blir ägaren underrättad.
                    </li>
                </ol>
                Idén bygger på AI (artificiell inteligens) och det som kallas DeepLearning.
            </p>
            <p>
                Nu i pilotfasen är allt fokus på att se om träningen kan bli tillräckligt bra.
            </p>
        </div>
        <h2 className="subtitle is-3">
            Gör så här
        </h2>
        <div className="content">
            Som ägare behöver du ta väldigt många bilder av din katt. Uppskattningsvis behövs runt 2000 bilder för att
            träningen av AI:t ska bli riktigt bra.
            <span
                className="is-text italic">Se till att det endast är den katt du tränar för nu som är på bild, det får inte finnas andra katter på bilden.
                </span>
            <div className="notification">
                <span><p><InfoIcon className="verticalbottom"/>&nbsp;
                    Tag 5 → 10 bilder åt gången då du vrider telefonen runt din katt.</p>
                    <p> Passa också på att ta bilder då katten gör olika
                        saker (så att hen inte alltid ligger och sover vid varje bildtagning)!</p>
                    <p>Försök även att variera tiden på dygnet då bilder tas, för ljuset och kattens dygnsrytms skull</p>
                    <p>Kan också vara bra att hålla lite olika avstånd från katten så att alla bilder inte blir extremt in- eller utzoomade.</p>
                    <p>Om Du vill få riktigt bra träning bör du låta någon som katten inte är van vid fotografera den också.
                        Det kan nämligen vara så att katten alltid ser stöddig eller trygg ut när matte eller husse är med, men alltid lite rädd då okända tvåbeningar är nära. Så gärna lite foton när du har gäster hemma också (dock inga människor på bilden).</p>
                </span>
            </div>
            <div className="notification is-warning">
                <AnnouncementIcon className="verticalbottom"/> &nbsp;
                <p>Just nu ska du se till att alltid ha telefonen i upprätt läge (så att den inte byter perspektiv).
                    Annars
                    blir bildens skalning fel tyvärr!</p>
                <p>Du ska heller inte få med några människor på bilden, helst inte ens delar av människor!</p>
            </div>
        </div>
        <div className="content">
            <img src={img}/>
            <div className="notification is-info">

                Du får feedback i form av hur många bilder som du tagit av katten.
                <br/>
                <SummaryForCat camViewClass={"CatCamView demo"}
                               capture={() => {
                               }}
                               catName={"Storm"}
                               pictureSummary={{
                                   cardinalities: [
                                       {
                                           count: 1,
                                           category: PictureCategory.Uploaded
                                       },
                                       {
                                           count: 360,
                                           category: PictureCategory.Autoannotated,
                                       },
                                       {
                                           count: 50,
                                           category: PictureCategory.Approved,
                                       }
                                   ]
                               }}

                />
                <p>I det här exemplet är</p>
                <ul>
                    <li>Storm: kattens namn</li>
                    <li>Första talet (1): antalet bilder som är helt oprocessade.</li>
                    <li>Andra talet (360): antalet bilder som är förprocessade (automatiskt annoterade).</li>
                    <li>Tredje talet (50): antalet bilder som är godkända.</li>
                </ul>
                Färgkoderna är röd (<span className="is-text has-text-danger">11</span>) för att antalet är för få,
                orange (<span className="is-text has-text-warning">600</span>) för att antalet är ok men inte optimalt
                och
                grön (<span className="is-text has-text-success">1102</span>) för att antalet bilder är bra.
            </div>
            <div className="notification is-info">
                För att få över bilder till godkända behöver du betygsättta bilderna. Under &nbsp;<a
                href={`https://hasseltass.se/#/${HasseltassResource.PICTURES}`}>https://hasseltass.se/#/{HasseltassResource.PICTURES}</a> finns
                alla bilder som du tagit.
                Det går endast att betygsätta bilder där exakt en katt blivit detekterad.
                <RatingTip/>
            </div>
        </div>
    </>
}


export default StaticOwnerInformation