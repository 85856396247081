/* tslint:disable */
/* eslint-disable */
/**
 * Hasseltass API
 * To search for cats from given image.
 *
 * The version of the OpenAPI document: 2.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    ApiErrorBody,
    ApiErrorBodyFromJSON,
    ApiErrorBodyToJSON,
    ArrayOfCats,
    ArrayOfCatsFromJSON,
    ArrayOfCatsToJSON,
    PictureSummary,
    PictureSummaryFromJSON,
    PictureSummaryToJSON,
    UserInfoResponse,
    UserInfoResponseFromJSON,
    UserInfoResponseToJSON,
} from '../models';

export interface PictureCardinalityRequest {
    catid: number;
}

export interface PictureTakenRequest {
    catid: number;
    file?: Blob;
}

/**
 * 
 */
export class TrainingApi extends runtime.BaseAPI {

    /**
     * Return a list for all cats that this user can train on. A cat is available if it either is owned by the given user, or is related to a user that owns that cat.
     * Get all cats used for training for given user. Will only return anything if logged in user is related to (or is) the given userid.
     */
    async availableCatsRaw(): Promise<runtime.ApiResponse<ArrayOfCats>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/private/logged-in-user/available-cats`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ArrayOfCatsFromJSON(jsonValue));
    }

    /**
     * Return a list for all cats that this user can train on. A cat is available if it either is owned by the given user, or is related to a user that owns that cat.
     * Get all cats used for training for given user. Will only return anything if logged in user is related to (or is) the given userid.
     */
    async availableCats(): Promise<ArrayOfCats> {
        const response = await this.availableCatsRaw();
        return await response.value();
    }

    /**
     * A user has logged in. The backend should make sure that the user exists in persistence context.
     * Indicates for the backend that a user has recently logged in.
     */
    async helloRaw(): Promise<runtime.ApiResponse<void>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/private/logged-in-user/hello`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * A user has logged in. The backend should make sure that the user exists in persistence context.
     * Indicates for the backend that a user has recently logged in.
     */
    async hello(): Promise<void> {
        await this.helloRaw();
    }

    /**
     * Returns the number of pictures that currently is taken for given cat. If the user is not allowed to see given cat a 403 is raised.
     * Gets the number of pictures taken for given cat.
     */
    async pictureCardinalityRaw(requestParameters: PictureCardinalityRequest): Promise<runtime.ApiResponse<PictureSummary>> {
        if (requestParameters.catid === null || requestParameters.catid === undefined) {
            throw new runtime.RequiredError('catid','Required parameter requestParameters.catid was null or undefined when calling pictureCardinality.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/private/logged-in-user/cats/{catid}/picture-cardinality`.replace(`{${"catid"}}`, encodeURIComponent(String(requestParameters.catid))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => PictureSummaryFromJSON(jsonValue));
    }

    /**
     * Returns the number of pictures that currently is taken for given cat. If the user is not allowed to see given cat a 403 is raised.
     * Gets the number of pictures taken for given cat.
     */
    async pictureCardinality(requestParameters: PictureCardinalityRequest): Promise<PictureSummary> {
        const response = await this.pictureCardinalityRaw(requestParameters);
        return await response.value();
    }

    /**
     * A picture will be uploaded to server and initial detection will be performed. It will also persist the current state of the picture to persistence context.
     * Uploads a picture with multiform data.
     */
    async pictureTakenRaw(requestParameters: PictureTakenRequest): Promise<runtime.ApiResponse<PictureSummary>> {
        if (requestParameters.catid === null || requestParameters.catid === undefined) {
            throw new runtime.RequiredError('catid','Required parameter requestParameters.catid was null or undefined when calling pictureTaken.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const consumes: runtime.Consume[] = [
            { contentType: 'multipart/form-data' },
        ];
        // @ts-ignore: canConsumeForm may be unused
        const canConsumeForm = runtime.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): any };
        let useForm = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new URLSearchParams();
        }

        if (requestParameters.file !== undefined) {
            formParams.append('file', requestParameters.file as any);
        }

        const response = await this.request({
            path: `/api/private/logged-in-user/cats/{catid}/picture-taken`.replace(`{${"catid"}}`, encodeURIComponent(String(requestParameters.catid))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: formParams,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => PictureSummaryFromJSON(jsonValue));
    }

    /**
     * A picture will be uploaded to server and initial detection will be performed. It will also persist the current state of the picture to persistence context.
     * Uploads a picture with multiform data.
     */
    async pictureTaken(requestParameters: PictureTakenRequest): Promise<PictureSummary> {
        const response = await this.pictureTakenRaw(requestParameters);
        return await response.value();
    }

    /**
     * The login API, that provides the cookie/token to use with the API\'s
     * Login to the API
     */
    async userInfoRaw(): Promise<runtime.ApiResponse<UserInfoResponse>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/private/user-info`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => UserInfoResponseFromJSON(jsonValue));
    }

    /**
     * The login API, that provides the cookie/token to use with the API\'s
     * Login to the API
     */
    async userInfo(): Promise<UserInfoResponse> {
        const response = await this.userInfoRaw();
        return await response.value();
    }

}
