/* tslint:disable */
/* eslint-disable */
/**
 * Hasseltass API
 * To search for cats from given image.
 *
 * The version of the OpenAPI document: 2.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface Dimension
 */
export interface Dimension {
    /**
     * 
     * @type {number}
     * @memberof Dimension
     */
    width: number;
    /**
     * 
     * @type {number}
     * @memberof Dimension
     */
    height: number;
}

export function DimensionFromJSON(json: any): Dimension {
    return DimensionFromJSONTyped(json, false);
}

export function DimensionFromJSONTyped(json: any, ignoreDiscriminator: boolean): Dimension {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'width': json['width'],
        'height': json['height'],
    };
}

export function DimensionToJSON(value?: Dimension | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'width': value.width,
        'height': value.height,
    };
}


