import catService from "../service/CatService";
import pictureService from "../service/PictureService";
import {updateOneCat} from "./cat/UpdateOneCat";
import {createOneCat} from "./cat/CreateOneCat";
import deleteCat from "./cat/DeleteCat";
import photoService from "../service/PhotoService";
import {createOneCatFromPhotosView} from "./cat/CreateOneCatFromPhotos";


export enum HasseltassResource {
    PHOTOS = 'photo',
    CATS = 'cat',
    PICTURES = 'picture',
}


export const HasseltassProvider = {
    getList: (resource: HasseltassResource, params: any): Promise<any> => {
        switch (resource) {
            case HasseltassResource.CATS:
                return catService.allCats()
            case HasseltassResource.PICTURES:
                return pictureService.allPictures(params);
            case HasseltassResource.PHOTOS:
                return photoService.allSummaries();
            default:
                return REJECT(resource, 'getList');
        }
    },
    getOne: (resource: HasseltassResource, params: any): Promise<any> => {
        switch (resource) {
            case HasseltassResource.PHOTOS:
                return photoService.oneSummary(params.id)
            case HasseltassResource.CATS:
                return catService.getOneCat(params.id)
            default:
                return REJECT(resource, "getOne");
        }
    },

    create: (resource: HasseltassResource, params: any): Promise<any> => {
        switch (resource) {
            case HasseltassResource.CATS:
                return createOneCat(params)
            case HasseltassResource.PHOTOS:
                return createOneCatFromPhotosView(params)
            default:
                return REJECT(resource, "create");
        }
    },
    update: (resource: HasseltassResource, params: any): Promise<any> => {
        switch (resource) {
            case HasseltassResource.CATS:
                return updateOneCat({...params, catId: params.id})
            default:
                return REJECT(resource, "update");
        }
    },

    delete: (resource: HasseltassResource, params: any): Promise<any> => {
        switch (resource) {
            case HasseltassResource.CATS:
                return deleteCat({...params, catId: params.id});
            case HasseltassResource.PICTURES:
                return pictureService.deleteOnePicture(params.id);
            default:
                return REJECT(resource, 'delete');
        }
    },

    deleteMany: (resource: HasseltassResource, params: any): Promise<any> => {
        switch (resource) {
            case HasseltassResource.CATS:
                params.ids.forEach((id: number) => {
                    HasseltassProvider.delete(resource, {id: id})
                })
                return Promise.resolve({data: params.ids})
            case HasseltassResource.PICTURES:
                params.ids.forEach((id: string) => {
                        HasseltassProvider.delete(resource, {id: id})
                    }
                )
                return Promise.resolve({data: params.ids})
            default:
                return REJECT(resource, 'deleteMany');
        }
    }
    ,

}
const REJECT = (resource: HasseltassResource, func: String) => Promise.reject(`Unknown resource '${resource}' not implemented for ${func}.`)
