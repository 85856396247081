/* tslint:disable */
/* eslint-disable */
/**
 * Hasseltass API
 * To search for cats from given image.
 *
 * The version of the OpenAPI document: 2.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    ApiErrorBody,
    ApiErrorBodyFromJSON,
    ApiErrorBodyToJSON,
    BatchPicturesResponse,
    BatchPicturesResponseFromJSON,
    BatchPicturesResponseToJSON,
    PictureMetaData,
    PictureMetaDataFromJSON,
    PictureMetaDataToJSON,
    PictureParameters,
    PictureParametersFromJSON,
    PictureParametersToJSON,
    UpdatePictureRating,
    UpdatePictureRatingFromJSON,
    UpdatePictureRatingToJSON,
} from '../models';

export interface DeletePictureRequest {
    pictureId: number;
}

export interface OnePictureRequest {
    pictureFilename: string;
}

export interface PicturesRequest {
    pictureParameters?: PictureParameters;
}

export interface RatePictureRequest {
    pictureFilename: string;
    updatePictureRating?: UpdatePictureRating;
}

/**
 * 
 */
export class AdminPictureApi extends runtime.BaseAPI {

    /**
     * Removes the given picture
     * Deletes picture
     */
    async deletePictureRaw(requestParameters: DeletePictureRequest): Promise<runtime.ApiResponse<PictureMetaData>> {
        if (requestParameters.pictureId === null || requestParameters.pictureId === undefined) {
            throw new runtime.RequiredError('pictureId','Required parameter requestParameters.pictureId was null or undefined when calling deletePicture.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/private/logged-in-user/pictures/{pictureId}`.replace(`{${"pictureId"}}`, encodeURIComponent(String(requestParameters.pictureId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => PictureMetaDataFromJSON(jsonValue));
    }

    /**
     * Removes the given picture
     * Deletes picture
     */
    async deletePicture(requestParameters: DeletePictureRequest): Promise<PictureMetaData> {
        const response = await this.deletePictureRaw(requestParameters);
        return await response.value();
    }

    /**
     * Meta data for one picture
     * Get one picture with all meta data
     */
    async onePictureRaw(requestParameters: OnePictureRequest): Promise<runtime.ApiResponse<PictureMetaData>> {
        if (requestParameters.pictureFilename === null || requestParameters.pictureFilename === undefined) {
            throw new runtime.RequiredError('pictureFilename','Required parameter requestParameters.pictureFilename was null or undefined when calling onePicture.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/private/logged-in-user/pictures/{pictureFilename}`.replace(`{${"pictureFilename"}}`, encodeURIComponent(String(requestParameters.pictureFilename))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => PictureMetaDataFromJSON(jsonValue));
    }

    /**
     * Meta data for one picture
     * Get one picture with all meta data
     */
    async onePicture(requestParameters: OnePictureRequest): Promise<PictureMetaData> {
        const response = await this.onePictureRaw(requestParameters);
        return await response.value();
    }

    /**
     * Pictures for the logged in user is returned. Given the current filter and sorting.
     * Get pictures for logged in user.
     */
    async picturesRaw(requestParameters: PicturesRequest): Promise<runtime.ApiResponse<BatchPicturesResponse>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/private/logged-in-user/pictures`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: PictureParametersToJSON(requestParameters.pictureParameters),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => BatchPicturesResponseFromJSON(jsonValue));
    }

    /**
     * Pictures for the logged in user is returned. Given the current filter and sorting.
     * Get pictures for logged in user.
     */
    async pictures(requestParameters: PicturesRequest): Promise<BatchPicturesResponse> {
        const response = await this.picturesRaw(requestParameters);
        return await response.value();
    }

    /**
     * Meta data for one picture
     * Updates the rating for a picture
     */
    async ratePictureRaw(requestParameters: RatePictureRequest): Promise<runtime.ApiResponse<PictureMetaData>> {
        if (requestParameters.pictureFilename === null || requestParameters.pictureFilename === undefined) {
            throw new runtime.RequiredError('pictureFilename','Required parameter requestParameters.pictureFilename was null or undefined when calling ratePicture.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/private/logged-in-user/pictures/{pictureFilename}/rate`.replace(`{${"pictureFilename"}}`, encodeURIComponent(String(requestParameters.pictureFilename))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: UpdatePictureRatingToJSON(requestParameters.updatePictureRating),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => PictureMetaDataFromJSON(jsonValue));
    }

    /**
     * Meta data for one picture
     * Updates the rating for a picture
     */
    async ratePicture(requestParameters: RatePictureRequest): Promise<PictureMetaData> {
        const response = await this.ratePictureRaw(requestParameters);
        return await response.value();
    }

}
