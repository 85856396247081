import {CreateCat} from "../../generated-sources/models";
import catService from "../../service/CatService";

export interface UpdateOneCatParam {
    userId: number,
    catId: number,
    data: any
}

export const updateOneCat = (params: UpdateOneCatParam): Promise<any> => {
    const {data} = params;
    const updatedCat: CreateCat = {
        name: data.name
    }
    return catService.updateOneCat(params.catId, updatedCat).then(c => {
        return {data: c}
    })
}