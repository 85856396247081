/* tslint:disable */
/* eslint-disable */
/**
 * Hasseltass API
 * To search for cats from given image.
 *
 * The version of the OpenAPI document: 2.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Dimension,
    DimensionFromJSON,
    DimensionFromJSONTyped,
    DimensionToJSON,
    Point,
    PointFromJSON,
    PointFromJSONTyped,
    PointToJSON,
} from './';

/**
 * 
 * @export
 * @interface BoundingBox
 */
export interface BoundingBox {
    /**
     * 
     * @type {Point}
     * @memberof BoundingBox
     */
    point: Point;
    /**
     * 
     * @type {Dimension}
     * @memberof BoundingBox
     */
    dimension: Dimension;
}

export function BoundingBoxFromJSON(json: any): BoundingBox {
    return BoundingBoxFromJSONTyped(json, false);
}

export function BoundingBoxFromJSONTyped(json: any, ignoreDiscriminator: boolean): BoundingBox {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'point': PointFromJSON(json['point']),
        'dimension': DimensionFromJSON(json['dimension']),
    };
}

export function BoundingBoxToJSON(value?: BoundingBox | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'point': PointToJSON(value.point),
        'dimension': DimensionToJSON(value.dimension),
    };
}


