import {CreateCat, PictureCategory, PictureSummary} from "../../generated-sources/models";
import catService from "../../service/CatService";
import {Provided} from "../../service/TokenHelper";

export interface CreateOneCatParam {
    userId: number,
    data: any
}

export const createOneCatFromPhotosView = (params: CreateOneCatParam): Promise<Provided<PictureSummary>> => {
    const {data} = params;
    const createCat: CreateCat = {
        name: data.name
    }
    return catService.createOneCat(createCat).then(c => {
        return {
            data: {
                cardinalities: [{
                    category: PictureCategory.Uploaded,
                    count: 0
                }, {
                    category: PictureCategory.Autoannotated,
                    count: 0
                }, {
                    category: PictureCategory.Requested,
                    count: 0
                }, {
                    category: PictureCategory.Approved,
                    count: 0
                }]
            },
            total: 1
        }
    })
}