import React, {useState} from 'react'
import {Show, useAuthenticated, useNotify} from 'react-admin'
import CatCamera from "./CatCamera";
import {Cat, PictureSummary, User} from "../../generated-sources/models";
import {isMobile} from "react-device-detect";
import photoService from "../../service/PhotoService";

const TakePhoto = (props: any) => {
    return (
        <Show {...props}>
            <Camera record={props.record}/>
        </Show>
    )
}


const Camera = (props: { record: { cat: Cat, owner: User, summary: PictureSummary }, rest?: any[] }) => {
    useAuthenticated()
    const {cat, owner, summary} = props.record
    const [pictureSummary, setPictureSummary] = useState<PictureSummary>(summary);
    const notify = useNotify();
    const upload = (img:string) => {
        photoService.uploadPhoto(cat.id, img).then(pictureSummary => {
            setPictureSummary(pictureSummary)
        }).catch(error => {
            notify("Bilden kunder inte sparas tyvärr!.", "warning")
        })
    }

    if (!isMobile && !window.confirm("Fotografering är inte tänkt att användas från en dator, du ska använda en mobiltelefon. Vill du ändå fortsätta i testsyfte?"))
        return (<>
            <span> Ingen kamera får användas från dator.</span>
            </>)
    return (
        <>
            <CatCamera catName={cat.name} pictureSummary={pictureSummary}
                       pictureHandler={(img:string) => upload(img)}/>
        </>
    )
}

export default TakePhoto