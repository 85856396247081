/* tslint:disable */
/* eslint-disable */
/**
 * Hasseltass API
 * To search for cats from given image.
 *
 * The version of the OpenAPI document: 2.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Cat,
    CatFromJSON,
    CatFromJSONTyped,
    CatToJSON,
} from './';

/**
 * 
 * @export
 * @interface ArrayOfCats
 */
export interface ArrayOfCats {
    /**
     * 
     * @type {Array<Cat>}
     * @memberof ArrayOfCats
     */
    cats: Array<Cat>;
}

export function ArrayOfCatsFromJSON(json: any): ArrayOfCats {
    return ArrayOfCatsFromJSONTyped(json, false);
}

export function ArrayOfCatsFromJSONTyped(json: any, ignoreDiscriminator: boolean): ArrayOfCats {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'cats': ((json['cats'] as Array<any>).map(CatFromJSON)),
    };
}

export function ArrayOfCatsToJSON(value?: ArrayOfCats | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'cats': ((value.cats as Array<any>).map(CatToJSON)),
    };
}


