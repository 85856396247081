import {CatWithSummary, PictureSummary, PictureTakenRequest, TrainingApi} from "../generated-sources";
import createConfiguration, {Provided} from "./TokenHelper";
import moment from "moment";

class PhotoService {

    private api(): TrainingApi {
        return new TrainingApi(createConfiguration());
    }

    hello(): Promise<void> {
        console.log("saying hello to backend");
        return this.api().hello();
    }

    allSummaries(): Promise<Provided<Array<CatWithSummary>>> {
        return this.api().userInfo()
            .then(userInfo => userInfo.availableCats)
            .then(appendIds)
            .then(catWithSummaries => {
                console.log("cat with summaries: ", catWithSummaries)
                const provided: Provided<Array<CatWithSummary>> = {
                    total: catWithSummaries.length,
                    data: catWithSummaries
                }
                return provided
            })

    }

    oneSummary(catid: number) : Promise<Provided<PictureSummary>> {
        return this.api().pictureCardinality({ catid: catid}).then( pictureSummary => {
                const ret : Provided<PictureSummary> = {
                    total: 1,
                    data: pictureSummary
                }
                return ret;
            }
         )
    }

    uploadPhoto(catid: number, base64:string) : Promise<PictureSummary> {
        const mother = this;
        console.log("uploading image", mother)
        return fetch(base64)
            .then(res => res.blob())
            .then(blob => {
                const timestamp:string = moment().format('x'); //To expoct
                console.log(timestamp)
                const request: PictureTakenRequest = {
                    catid: catid,
                    file: new File([blob], `cat-${catid}-${timestamp}.jpg`)
                }
                return request
            })
            .then(request => mother.api().pictureTaken(request))
    }

}

interface WithId {
    id: any
}

interface SummaryWithId extends CatWithSummary, WithId{
}

const appendId = (it: CatWithSummary): SummaryWithId => {
    return {
        ...it,
        id: it.cat.id
    }
}

const appendIds = (inp: Array<CatWithSummary>): Array<SummaryWithId> => {
    return inp.map(appendId)

}


const photoService: PhotoService = new PhotoService();
export default photoService
