import * as React from 'react';
import {FC, memo, ReactElement} from 'react';
import PropTypes from 'prop-types';
import CameraIcon from '@material-ui/icons/Camera';
import {Link} from 'react-router-dom';
import {linkToRecord, Record} from 'ra-core';


import {Button, ButtonProps} from 'react-admin';

const PhotoButton: FC<PhotoButtonProps> = ({
                                               basePath = '',
                                               label = 'ra.action.photo',
                                               record,
                                               icon = defaultIcon,
                                               ...rest
                                           }) => (
    <Button
        component={Link}
        to={`${linkToRecord("photo", record && record.id)}/show`}
        label={label}
        onClick={stopPropagation}
        {...(rest as any)}
    >
        {icon}
    </Button>
);

const defaultIcon = <CameraIcon/>;

// useful to prevent click bubbling in a datagrid with rowClick
const stopPropagation = (e: any) => e.stopPropagation()

interface Props {
    basePath?: string;
    record?: Record;
    icon?: ReactElement;
}

// @ts-ignore
export type PhotoButtonProps = Props & ButtonProps;

PhotoButton.propTypes = {
    basePath: PropTypes.string,
    icon: PropTypes.element,
    label: PropTypes.string,
    record: PropTypes.any,
};

const PurePhotoButton = memo(
    PhotoButton,
    (props: Props, nextProps: Props) =>
        (props.record && nextProps.record
            ? props.record.id === nextProps.record.id
            : props.record == nextProps.record) && // eslint-disable-line eqeqeq
        props.basePath === nextProps.basePath
);

export default PurePhotoButton;
