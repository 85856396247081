import * as React from "react";
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import { Title } from 'react-admin';
import StaticOwnerInformation from "../photo/StaticOwnerInformation";
export default () => (
    <Card>
        <Title title="Välkommen till hasseltass!" />
        <CardContent>
            <StaticOwnerInformation/>
        </CardContent>
    </Card>
);