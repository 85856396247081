/* tslint:disable */
/* eslint-disable */
/**
 * Hasseltass API
 * To search for cats from given image.
 *
 * The version of the OpenAPI document: 2.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface SortItem
 */
export interface SortItem {
    /**
     * 
     * @type {string}
     * @memberof SortItem
     */
    field?: string;
    /**
     * 
     * @type {string}
     * @memberof SortItem
     */
    order?: SortItemOrderEnum;
}

export function SortItemFromJSON(json: any): SortItem {
    return SortItemFromJSONTyped(json, false);
}

export function SortItemFromJSONTyped(json: any, ignoreDiscriminator: boolean): SortItem {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'field': !exists(json, 'field') ? undefined : json['field'],
        'order': !exists(json, 'order') ? undefined : json['order'],
    };
}

export function SortItemToJSON(value?: SortItem | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'field': value.field,
        'order': value.order,
    };
}

/**
* @export
* @enum {string}
*/
export enum SortItemOrderEnum {
    ASC = 'ASC',
    DEC = 'DEC'
}


