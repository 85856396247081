import React from "react";
import {PictureCardinality, PictureCategory, PictureSummary} from "../../generated-sources/models";
import './SummaryForCat.css'

export const SummaryForCat = (props: { camViewClass?: string, capture: any, catName: string, pictureSummary: PictureSummary }) => {
    const {camViewClass, catName} = props;
    const cssClasses = "CatCamView " + (camViewClass ? camViewClass : "")
    return (<>
            <div className={cssClasses} onClick={props.capture}>
                <span>{props.catName}</span> {
                // TODO use css styling instead of nbsps
            }
                <div className="PictureStateInformation">
                    <Summary pictureSummary={props.pictureSummary} category={PictureCategory.Uploaded}/> &nbsp;&nbsp;
                    <Summary pictureSummary={props.pictureSummary}
                             category={PictureCategory.Autoannotated}/> &nbsp;&nbsp;
                    <Summary pictureSummary={props.pictureSummary} category={PictureCategory.Approved}/> &nbsp;&nbsp;
                </div>
            </div>
        </>
    )
}

const Summary = (props: { pictureSummary: PictureSummary | null, category: PictureCategory }) => {
    let cardinality: number = accumulatedCardinality(props.pictureSummary, props.category)
    let clazz = "Small"
    if (cardinality > 1000)
        clazz = "Good"
    else if (cardinality > 300)
        clazz = "Medium"
    return (
        <>
            <span className={`NumberOfPicturesTaken ${clazz}`}>{getCount(props.pictureSummary, props.category)}</span>
        </>
    )
}

const  accumulatedCardinality = (summary: PictureSummary | null, category: PictureCategory) : number => {
    let count : number = getCount(summary, category)
    if (category > PictureCategory.Autoannotated) {
        count += getCount(summary, PictureCategory.Autoannotated)
    }
    if (category > PictureCategory.Approved) {
        count += getCount(summary, PictureCategory.Approved)
    }
    return count
}

const getCount = (summary: PictureSummary | null, category: PictureCategory) => {
    const curr:number | undefined =  summary?.cardinalities.find((p:PictureCardinality) => p.category === category)?.count
    if (curr)
        return curr
    else
        return 0
}