import React, {CSSProperties, Fragment, useEffect, useState} from "react"
import {BulkDeleteButton, Filter, List, NumberInput, Pagination, useAuthenticated, useListContext} from "react-admin"
import inflection from 'inflection';
import {BoundingBox, Dimension, PictureMetaData} from "../../generated-sources/models";
import {Card, CardMedia, Chip, Grid, makeStyles,} from '@material-ui/core';
import './PictureList.css'
import PetsIcon from '@material-ui/icons/Pets';
import DoneOutlineIcon from '@material-ui/icons/DoneOutline';
import Tooltip from '@material-ui/core/Tooltip';
import Ratings from 'react-ratings-declarative';
import pictureService from "../../service/PictureService";
import {createMuiTheme} from '@material-ui/core/styles';
import RatingHelpButton from "../photo/RatingHelpButton";


const PictureGrid = (props: any) => {
    const {
        basePath,
        currentSort,
        data,
        ids,
        loaded,
        onSelect,
        onToggleItem,
        resource,
        selectedIds,
        setSort,
        total,
    } = useListContext(props);
    console.log("selected from grid: ", selectedIds)
    useAuthenticated()
    return ids ? (
        <>
            <Grid container spacing={2}>
                {
                    ids.filter((id: any) => data[id]).map((id: any) => <OneImage id={id} title={"" + id}
                                                                                 filename={data[id].filename}
                                                                                 selectedIds={selectedIds}
                                                                                 picture={data[id]}
                                                                                 selectionChange={onToggleItem}/>)
                }
            </Grid>
        </>
    ) : null
}

function pictureHeight() {
    return 500;
}

function pictureWidth() {
    return 375;
}

const useStyles = makeStyles({
    root: {
        marginTop: '1em',
    },
    media: {
        height: pictureHeight(),
        width: pictureWidth()
    },
    title: {
        paddingBottom: '0.5em',
    },
    actionSpacer: {
        display: 'flex',
        justifyContent: 'space-around',
    },
});

const OneImage = (props: { id: any, title: string, filename: string, picture: PictureMetaData, selectedIds: Array<string>, selectionChange: (id: string, selected: boolean) => void }) => {
    const {id, filename, picture} = props
    const classes = useStyles(props);
    const [rating, setRating] = useState(picture.rating ? picture.rating : 0);
    const changeRating = picture.boundingBoxes?.length == 1 ? (newRating: number) => {
        console.log("Changing picture rating", picture.id, newRating)
        pictureService.ratePicture(picture.filename, newRating)
            .then(p => p.data.rating ? p.data.rating : 0)
            .then(setRating)
    } : undefined
    // On pagination when the rating is changed for same picture card, we must have a
    // dependency on the rating to an effect, otherwise we wont update the rating,
    // is also a problem if we use the rating filter in list and change ratings, obviously
    // we would like the rating on each picture to change as well. This is more like a workaround
    // to make that happen.
    useEffect(() => {
        setRating(picture.rating ? picture.rating : 0)
    }, [picture.id])
    useAuthenticated();

    const ratingWidgetDimension = "33px"
    const ratingWidgetSpacing = "3px"
    const [selected, setSelected] = useState(false);
    const [className, setClassName] = useState("pictureNotSelected");
    const toggleSelected = (newSelected: boolean) => {

        setSelected(newSelected)
        setClassName(newSelected ? "pictureSelected" : "pictureNotSelected")
        console.log("New classname: ", className)
        props.selectionChange(id, newSelected)
    }
    useEffect(() => {
        console.log("updating selected ")
        setSelected(props.selectedIds.indexOf(id) > -1)
    })

    return (<>
        <Grid key={id} xs={12} sm={6} md={4} lg={3} xl={2} item>
            <Card raised={selected} square={selected} className={className}>
                <CardMedia className={classes.media}
                           image={`${process.env.REACT_APP_IMAGES_BASE}/${filename}`}
                           style={{zIndex: -1}} onClick={() => toggleSelected(!selected)}
                >
                    <HandleSelection picture={picture} selected={selected}/>
                    <PictureDataView picture={picture}/></CardMedia>
                <div>
                    <Ratings rating={rating}
                             widgetRatedColors={"blue"}
                             changeRating={changeRating}
                             widgetDimensions={ratingWidgetDimension}
                             widgetSpacings={ratingWidgetSpacing}
                    >
                        <Ratings.Widget/>
                        <Ratings.Widget/>
                        <Ratings.Widget/>
                        <Ratings.Widget/>
                        <Ratings.Widget/>
                    </Ratings>
                    <RatingHelpButton/>
                </div>
            </Card>
        </Grid>
    </>)
}

/**
 * Just adds x, y, width and height to css rectangle.
 */
const rectangleCss = (boundingBox: BoundingBox, parentDimension: Dimension): CSSProperties => {
    const factor = pictureHeight() / parentDimension.height;
    const x = boundingBox.point.x * factor;
    const y = boundingBox.point.y * factor;
    const width = boundingBox.dimension.width * factor;
    const height = boundingBox.dimension.height * factor;

    return {
        width: width,
        height: height,
        left: x,
        top: y,
        zIndex: 0
    }
}

const PictureDataView = (props: { picture: PictureMetaData }) => {
    const {picture} = props;
    const css: CSSProperties = {
        backgroundColor: 'grey',
        opacity: 0.8,
        color: 'white'
    }
    useAuthenticated();
    return (<>
        <span style={css}>
        {inflection.humanize(picture.catName)}
        </span>
        <span>
            {picture.id}
        </span>
        <p>
            <NumberOfAutoDetection annotationCount={picture.boundingBoxes?.length}/>
        </p>
        {picture.boundingBoxes?.map(bb => <Rect box={bb} parentDimension={picture.dimension}/>)
        }

    </>)
}

const HandleSelection = (props: { picture: PictureMetaData, selected: boolean }) => {
    return (<> {props.selected && <div className={"selected"}>
        <div><DoneOutlineIcon fontSize={"large"}/></div>
    </div>}
    </>)
}


const NumberOfAutoDetection = (props: { annotationCount: number | undefined }) => {
    const {annotationCount} = props;
    let color = 'grey'
    if (annotationCount === 1) {
        color = 'green'
    } else if (annotationCount && annotationCount > 1) {
        color = 'red'
    }
    return (
        <>
            <Tooltip title={<AnnotationTooltip annotationCount={annotationCount}/>}>
                <PetsIcon style={{color: color, backgroundColor: "lightgrey"}}/>
            </Tooltip>
        </>
    );
}

const AnnotationTooltip = (props: { annotationCount: number | undefined }) => {
    const {annotationCount} = props;
    let text = ':( Inga katter funna.'
    if (annotationCount == 1) {
        text = ":) Perfekt! Exakt en katt kunde automatiskt detekteras"
    } else if (annotationCount && annotationCount > 1) {
        text = `:( Hittade ${annotationCount} katter. Svårt att veta vilken katt som är rätt.`
    }
    return (<>
        <p>Status för den automatiska detekteringen: {text}</p>
        <p><PetsIcon style={{color: "green"}}/>Grön färg betyder att antalet funna katter är rätt 1. <br/>
            <PetsIcon style={{color: "grey"}}/>Grå färg betyder att inga katter kunde hittas. <br/>
            <PetsIcon style={{color: "red"}}/>Röd färg betyder att för många katter hittades på bilden.
        </p>

    </>)
}


const Rect = (props: { box: BoundingBox, parentDimension: Dimension }) => (
    <>
        <div className="rectangle" style={rectangleCss(props.box, props.parentDimension)}/>
    </>
)


const BulkActionButtons = (props: any) => (
    <Fragment>
        {/* default bulk delete action */}
        <BulkDeleteButton {...props} />
    </Fragment>
);


const PictureList = (props: any) => {
    const [selectedIds, updateSelectedIds] = useState<Array<string>>([])
    const selectionChange = (id: string, selected: boolean) => {
        const selectedIndex = selectedIds.indexOf(id);
        let newSelection = selectedIds
        console.log("Selection change", id, selected)
        if (!selected && selectedIndex >= 0) {
            console.log("Unselecting...", id, selectedIndex)
            selectedIds.splice(selectedIndex, 1)
            newSelection = [...selectedIds]
        } else {
            console.log("Selecting...", id, selectedIds)
            newSelection = [...selectedIds, id]
        }
        updateSelectedIds([...newSelection])
        console.log("selection is now", newSelection)
    }
    return (

        <List title={"Bilder för träning"}
              exporter={false}
              filterDefaultValues={{shouldBeRated: true}}
              filters={<PictureFilter/>}
              {...props}
              sort={{field: 'id', order: 'DESC'}}
              perPage={20}
              pagination={<Pagination rowsPerPageOptions={[10, 20, 40]}/>}
              component="div"
            //bulkActionButtons={<BulkActionButtons />}
            //actions={filterValues}
        >
            {/*
        // @ts-ignore */}
            <PictureGrid selectionChange={selectionChange} selectedIds={selectedIds}
                         onToggleItem={() => console.log("Toggle item")} onSelect={() => updateSelectedIds([])}/>
        </List>
    )
};
const useQuickFilterStyles = makeStyles(theme => ({
    chip: {
        marginBottom: theme.spacing(1),
    },
}));

const numberInputStyles = createMuiTheme(
    {
        palette: {
            type: 'dark', // Switching the dark mode on is a single property value change.
        },
    }
);

const PictureFilter = (props: any) => (
    <Filter {...props}>
        <QuickFilter source="shouldBeRated" label="Att betygsätta" defaultValue/>
        <NumberInput className={numberInputStyles} label={"Betyg"} source="rating" defaultValue={3} min={1} max={5}/>
    </Filter>
)


const QuickFilter = (props: any) => {
    //const translate = useTranslate();
    //const classes = useQuickFilterStyles();
    // @ts-ignore
    return <Chip className={useQuickFilterStyles().chip} label={props.label}/>;
};

export default PictureList;