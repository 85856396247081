import React from "react"
import {EditButton, Datagrid, List, TextField, Button} from "react-admin"
import PhotoButton from "../components/PhotoButton";

const CatList = (props: any) => {
    return (
        <List {...props} rowClick="show" exporter={false} title={"Mina katter"}>
        <Datagrid>
            <TextField label="Namn" source="name"/>
            <EditButton/>
        </Datagrid>
        </List>
    )
}

export default CatList;