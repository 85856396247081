/* tslint:disable */
/* eslint-disable */
/**
 * Hasseltass API
 * To search for cats from given image.
 *
 * The version of the OpenAPI document: 2.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    CatWithSummary,
    CatWithSummaryFromJSON,
    CatWithSummaryFromJSONTyped,
    CatWithSummaryToJSON,
    User,
    UserFromJSON,
    UserFromJSONTyped,
    UserToJSON,
} from './';

/**
 * 
 * @export
 * @interface UserInfoResponse
 */
export interface UserInfoResponse {
    /**
     * 
     * @type {User}
     * @memberof UserInfoResponse
     */
    user: User;
    /**
     * 
     * @type {Array<CatWithSummary>}
     * @memberof UserInfoResponse
     */
    availableCats: Array<CatWithSummary>;
}

export function UserInfoResponseFromJSON(json: any): UserInfoResponse {
    return UserInfoResponseFromJSONTyped(json, false);
}

export function UserInfoResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): UserInfoResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'user': UserFromJSON(json['user']),
        'availableCats': ((json['availableCats'] as Array<any>).map(CatWithSummaryFromJSON)),
    };
}

export function UserInfoResponseToJSON(value?: UserInfoResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'user': UserToJSON(value.user),
        'availableCats': ((value.availableCats as Array<any>).map(CatWithSummaryToJSON)),
    };
}


