import React from "react";
import { Layout } from "react-admin"

// This can be used to customize the GUI.
export const HasseltassLayout = (props: any) => (
    <Layout
        {...props}
/>
);

export default HasseltassLayout
