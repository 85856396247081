/* tslint:disable */
/* eslint-disable */
/**
 * Hasseltass API
 * To search for cats from given image.
 *
 * The version of the OpenAPI document: 2.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface PictureFilter
 */
export interface PictureFilter {
    /**
     * 
     * @type {number}
     * @memberof PictureFilter
     */
    rating?: number;
    /**
     * 
     * @type {boolean}
     * @memberof PictureFilter
     */
    shouldBeRated?: boolean;
}

export function PictureFilterFromJSON(json: any): PictureFilter {
    return PictureFilterFromJSONTyped(json, false);
}

export function PictureFilterFromJSONTyped(json: any, ignoreDiscriminator: boolean): PictureFilter {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'rating': !exists(json, 'rating') ? undefined : json['rating'],
        'shouldBeRated': !exists(json, 'shouldBeRated') ? undefined : json['shouldBeRated'],
    };
}

export function PictureFilterToJSON(value?: PictureFilter | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'rating': value.rating,
        'shouldBeRated': value.shouldBeRated,
    };
}


