/* tslint:disable */
/* eslint-disable */
/**
 * Hasseltass API
 * To search for cats from given image.
 *
 * The version of the OpenAPI document: 2.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum PictureCategory {
    Requested = 'requested',
    Uploaded = 'uploaded',
    Autoannotated = 'autoannotated',
    Approved = 'approved',
    Failed = 'failed'
}

export function PictureCategoryFromJSON(json: any): PictureCategory {
    return PictureCategoryFromJSONTyped(json, false);
}

export function PictureCategoryFromJSONTyped(json: any, ignoreDiscriminator: boolean): PictureCategory {
    return json as PictureCategory;
}

export function PictureCategoryToJSON(value?: PictureCategory | null): any {
    return value as any;
}

