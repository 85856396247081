import Ratings from "react-ratings-declarative";
import React from "react";

const RatingTip = (props: any) => (<>
        <p>
            Så här ska du tänka när du sätter betyg:
            <Rated rating={1}> Ingen katt har en rektangelmarkering, eller helt fel katt är markerad. </Rated>
            <Rated rating={2}> Rätt katt är rektangelmarkerad, men endast delar av katten är markerad.</Rated>
            <Rated rating={3}> Rätt katt och hela katten är rektangelmarkerad.</Rated>
            <Rated rating={4}> Rätt katt och hela katten är rektangelmarkerad, man ser också tydligt
                att det är rätt katt.</Rated>
            <Rated rating={5}> Rätt katt och hela katten är rektangelmarkerad, bilden fångar kattens
                typiska drag perfekt.</Rated>
        </p>
    </>
)


const Rated = (props: { rating: number, children: any }) => {
    const ratingWidgetDimension = "25px";
    const ratingWidgetSpacing = "3px";
    return (<>
            <p>
                <Ratings rating={props.rating}
                         widgetRatedColors={"blue"}
                         widgetDimensions={ratingWidgetDimension}
                         widgetSpacings={ratingWidgetSpacing}
                >
                    <Ratings.Widget/>
                    <Ratings.Widget/>
                    <Ratings.Widget/>
                    <Ratings.Widget/>
                    <Ratings.Widget/>
                </Ratings> &nbsp; <span>{props.children}</span>
            </p>
        </>
    )
}

export default RatingTip