/* tslint:disable */
/* eslint-disable */
/**
 * Hasseltass API
 * To search for cats from given image.
 *
 * The version of the OpenAPI document: 2.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    PictureMetaData,
    PictureMetaDataFromJSON,
    PictureMetaDataFromJSONTyped,
    PictureMetaDataToJSON,
} from './';

/**
 * 
 * @export
 * @interface BatchPicturesResponse
 */
export interface BatchPicturesResponse {
    /**
     * 
     * @type {Array<PictureMetaData>}
     * @memberof BatchPicturesResponse
     */
    pictures: Array<PictureMetaData>;
    /**
     * 
     * @type {number}
     * @memberof BatchPicturesResponse
     */
    total: number;
}

export function BatchPicturesResponseFromJSON(json: any): BatchPicturesResponse {
    return BatchPicturesResponseFromJSONTyped(json, false);
}

export function BatchPicturesResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): BatchPicturesResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'pictures': ((json['pictures'] as Array<any>).map(PictureMetaDataFromJSON)),
        'total': json['total'],
    };
}

export function BatchPicturesResponseToJSON(value?: BatchPicturesResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'pictures': ((value.pictures as Array<any>).map(PictureMetaDataToJSON)),
        'total': value.total,
    };
}


