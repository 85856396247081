/* tslint:disable */
/* eslint-disable */
/**
 * Hasseltass API
 * To search for cats from given image.
 *
 * The version of the OpenAPI document: 2.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    BoundingBox,
    BoundingBoxFromJSON,
    BoundingBoxFromJSONTyped,
    BoundingBoxToJSON,
    Dimension,
    DimensionFromJSON,
    DimensionFromJSONTyped,
    DimensionToJSON,
    PictureCategory,
    PictureCategoryFromJSON,
    PictureCategoryFromJSONTyped,
    PictureCategoryToJSON,
} from './';

/**
 * 
 * @export
 * @interface PictureMetaData
 */
export interface PictureMetaData {
    /**
     * 
     * @type {number}
     * @memberof PictureMetaData
     */
    id: number;
    /**
     * 
     * @type {string}
     * @memberof PictureMetaData
     */
    filename: string;
    /**
     * 
     * @type {PictureCategory}
     * @memberof PictureMetaData
     */
    category: PictureCategory;
    /**
     * 
     * @type {string}
     * @memberof PictureMetaData
     */
    originalName: string;
    /**
     * 
     * @type {number}
     * @memberof PictureMetaData
     */
    numberOfDetections?: number;
    /**
     * 
     * @type {string}
     * @memberof PictureMetaData
     */
    catName: string;
    /**
     * 
     * @type {Dimension}
     * @memberof PictureMetaData
     */
    dimension: Dimension;
    /**
     * 
     * @type {Array<BoundingBox>}
     * @memberof PictureMetaData
     */
    boundingBoxes?: Array<BoundingBox>;
    /**
     * 
     * @type {number}
     * @memberof PictureMetaData
     */
    rating?: number;
    /**
     * 
     * @type {boolean}
     * @memberof PictureMetaData
     */
    shouldBeRated: boolean;
}

export function PictureMetaDataFromJSON(json: any): PictureMetaData {
    return PictureMetaDataFromJSONTyped(json, false);
}

export function PictureMetaDataFromJSONTyped(json: any, ignoreDiscriminator: boolean): PictureMetaData {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'filename': json['filename'],
        'category': PictureCategoryFromJSON(json['category']),
        'originalName': json['originalName'],
        'numberOfDetections': !exists(json, 'numberOfDetections') ? undefined : json['numberOfDetections'],
        'catName': json['catName'],
        'dimension': DimensionFromJSON(json['dimension']),
        'boundingBoxes': !exists(json, 'boundingBoxes') ? undefined : ((json['boundingBoxes'] as Array<any>).map(BoundingBoxFromJSON)),
        'rating': !exists(json, 'rating') ? undefined : json['rating'],
        'shouldBeRated': json['shouldBeRated'],
    };
}

export function PictureMetaDataToJSON(value?: PictureMetaData | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'filename': value.filename,
        'category': PictureCategoryToJSON(value.category),
        'originalName': value.originalName,
        'numberOfDetections': value.numberOfDetections,
        'catName': value.catName,
        'dimension': DimensionToJSON(value.dimension),
        'boundingBoxes': value.boundingBoxes === undefined ? undefined : ((value.boundingBoxes as Array<any>).map(BoundingBoxToJSON)),
        'rating': value.rating,
        'shouldBeRated': value.shouldBeRated,
    };
}


