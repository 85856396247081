import React from "react"
import {
    BooleanField,
    Button,
    DateField,
    NumberField,
    Pagination,
    ReferenceManyField,
    RichTextField,
    Show,
    Tab,
    TabbedShowLayout,
    TextField,
    UrlField
} from "react-admin"

const CatTitle = ({record}: any) => {
    return <span>Administrera {record ? `"${record.name}"` : ''}</span>;
};
const CatShow = (props: any) => {
    //<UrlField label="Invitation URL" source="trainingUrl"/>
    return (<Show title={<CatTitle/>} {...props}>
        <TabbedShowLayout {...props} >
            <Tab label="Katt">
                <TextField label="ID" source="id"/>
                <TextField label="Kattnamn" source="name"/>

            </Tab>
        </TabbedShowLayout>
    </Show>)
}

export default CatShow