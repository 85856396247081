import React from "react"
import {Datagrid, FunctionField, List, TextField, useAuthenticated} from "react-admin"
import {PictureSummary} from "../../generated-sources/models";
import {SummaryForCat} from "./SummaryForCat";
import StaticOwnerInformation from "./StaticOwnerInformation";
import PhotoButton from '../components/PhotoButton'

const TakePhotoList = (props: any) => {
    useAuthenticated()
    return (
        <>
            <List {...props} bulkActionButtons={false} pagination={false} exporter={false} title={"Fotografera"}>
                <Datagrid>
                    <TextField label="Namn" source="cat.name"/>
                    {
                        //<TextField label="id" source="cat.id"/>
                    }
                    <PhotoButton />
                    <FunctionField label="Fotograferingsstatus" render={(record: any) =>
                        <Summary summary={record.summary} catName={record.cat.name}/>}/>
                </Datagrid>
            </List>
        </>
    )
}

const Summary = (props: { summary: PictureSummary, catName: string }) => {
    const {summary, catName} = props;
    return (<>
            <SummaryForCat pictureSummary={summary}
                           catName={catName}
                           capture={() => {
                           }}/>
        </>
    )
}

export default TakePhotoList;