import React from 'react';
import {Admin, Resource} from 'react-admin';
import './App.css';
import {isFirefox, isMobile} from "react-device-detect";
import {HasseltassProvider, HasseltassResource} from "./provider/HasseltassProvider";
import PictureList from "./view/cat/PictureList";
import HasseltassLayout from "./HasseltassLayout";
import CatShow from "./view/cat/CatShow";
import EditCat from "./view/cat/EditCat";
import CatCreate from "./view/cat/CatCreate";
import CatList from "./view/cat/CatList";
import moment, {Moment} from "moment";
import {
    KEYCLOAK_URL,
    REFRESH_TOKEN,
    TIMESTAMP_FOR_REFRESH,
    TOKEN,
    TRY_TO_REFRESH_BEFORE_SECONDS
} from "./config/Constants";
import TakePhotoList from "./view/photo/TakePhotoList";
import CameraIcon from '@material-ui/icons/Camera';
import PhotoLibraryIcon from '@material-ui/icons/PhotoLibrary';
import PetsIcon from '@material-ui/icons/Pets';
import TakePhoto from "./view/photo/TakePhoto";
import photoService from "./service/PhotoService";
import polyglotI18nProvider from 'ra-i18n-polyglot';
// @ts-ignore
import swedishMessages from 'ra-language-swedish';
import englishMessages from 'ra-language-english';
import Dashboard from "./view/dashboard/Dashboard";

const customSwedish = {
    resources: {
        cat : {
            name: "Katt||||Katter"
        },
        picture: {
            name: "Bild||||Bilder"
        },
        photo: {
            name: "Fotografera||||Fotografera"
        }
    },
    ra: {
        ...swedishMessages.ra,
        auth: {
            ...swedishMessages.ra.auth,
            auth_check_error: "Logga in för att fortsätta!"
        },
        page: {
            ...swedishMessages.ra.page,
            empty: "Det finns inga %{name} ännu.",
            invite: "Vill du lägga till en?"
        },
        action: {
            ...swedishMessages.ra.action,
            photo: "Fotografera",
            unselect: "Avmarkera",
            select: "Markera"
        }
    },


} //ra.action.unselect

const messages = {
    sv: customSwedish,
    en: englishMessages,
};
// @ts-ignore
const i18nProvider = polyglotI18nProvider(locale => messages[locale], 'sv');

function fetchCloakToken(request: Request) {
    return fetch(request)
        .then(response => {
            if (response.status < 200 || response.status >= 300) {
                throw new Error(response.statusText);
            }
            return response.json();
        })
        .then((json) => {
            localStorage.setItem(TOKEN, json.access_token);
            localStorage.setItem(REFRESH_TOKEN, json.refresh_token)
            const secondsUntilExpiration = Number(json.expires_in);
            localStorage.setItem(TIMESTAMP_FOR_REFRESH, moment().add(secondsUntilExpiration, "s").format());
        });
}

const authHeaders = new Headers(
    {
        "Content-Type": "application/x-www-form-urlencoded"
    })

const authUrl = KEYCLOAK_URL
const authProvider = {
    //@ts-ignore
    login: ({username, password}) => {
        console.log("Trying to login: ", username, authUrl);

        const request = new Request(authUrl, {
            method: 'POST',
            body: new URLSearchParams({
                "grant_type": "password",
                "client_id": "hasseltass",
                "username": username,
                "password": password
            }),
            headers: authHeaders
        });
        return fetchCloakToken(request)
            .then(() => photoService.hello());
    }, logout: (params: any) => {
        localStorage.removeItem(TOKEN)
        localStorage.removeItem(REFRESH_TOKEN)
        localStorage.removeItem(TIMESTAMP_FOR_REFRESH)
        return Promise.resolve()
    },
    checkAuth: (params: any) => {
        const tokenInStorage = localStorage.getItem(TOKEN)
        const refreshTokenInStorage = localStorage.getItem(REFRESH_TOKEN)
        const expirationInStorage = localStorage.getItem(TIMESTAMP_FOR_REFRESH)
        const expiration: Moment | undefined = expirationInStorage ? moment(expirationInStorage) : undefined
        let refreshTime = expiration?.clone();
        refreshTime?.subtract(TRY_TO_REFRESH_BEFORE_SECONDS, 's')
        const shouldRefresh: boolean = refreshTime != undefined ?
            moment().isBetween(refreshTime, expiration) : false
        if (shouldRefresh) {
            console.log("Will refresh access token...");
            const request = new Request(authUrl, {
                method: 'POST',
                body: new URLSearchParams({
                    "grant_type": "refresh_token",
                    "client_id": "hasseltass",
                    "refresh_token": refreshTokenInStorage ? refreshTokenInStorage : ""
                }),
                headers: authHeaders
            });
            return fetchCloakToken(request)
                .then()
        }
        const hasExpired = expiration != undefined && moment().isAfter(expiration)
        return tokenInStorage && !hasExpired
            ? Promise.resolve()
            : Promise.reject()
    },
    checkError: (error: any) => {
        const status = error.status;
        if (status === 401 || status === 403) {
            localStorage.removeItem(TOKEN)
            localStorage.removeItem(REFRESH_TOKEN)
            localStorage.removeItem(TIMESTAMP_FOR_REFRESH)
            return Promise.reject()
        }
        return Promise.resolve()
    },
    getPermissions: (params: any) => Promise.resolve(),
}

function App() {
    return (
        <>
            <Admin title="Hasseltass" layout={HasseltassLayout} dataProvider={HasseltassProvider}
                   authProvider={authProvider}
                   i18nProvider={i18nProvider}
                   dashboard={Dashboard}
            >
                <Resource name={HasseltassResource.PHOTOS} list={TakePhotoList} icon={CameraIcon} show={TakePhoto} />
                <Resource name={HasseltassResource.CATS} list={CatList} show={CatShow} edit={EditCat} create={CatCreate}
                          icon={PetsIcon}/>
                <Resource name={HasseltassResource.PICTURES} list={PictureList} icon={PhotoLibraryIcon}/>
            </Admin>
        </>
    );
}

export default App;
