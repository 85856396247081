/* tslint:disable */
/* eslint-disable */
/**
 * Hasseltass API
 * To search for cats from given image.
 *
 * The version of the OpenAPI document: 2.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    PictureCategory,
    PictureCategoryFromJSON,
    PictureCategoryFromJSONTyped,
    PictureCategoryToJSON,
} from './';

/**
 * 
 * @export
 * @interface PictureCardinality
 */
export interface PictureCardinality {
    /**
     * 
     * @type {number}
     * @memberof PictureCardinality
     */
    count: number;
    /**
     * 
     * @type {PictureCategory}
     * @memberof PictureCardinality
     */
    category: PictureCategory;
}

export function PictureCardinalityFromJSON(json: any): PictureCardinality {
    return PictureCardinalityFromJSONTyped(json, false);
}

export function PictureCardinalityFromJSONTyped(json: any, ignoreDiscriminator: boolean): PictureCardinality {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'count': json['count'],
        'category': PictureCategoryFromJSON(json['category']),
    };
}

export function PictureCardinalityToJSON(value?: PictureCardinality | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'count': value.count,
        'category': PictureCategoryToJSON(value.category),
    };
}


