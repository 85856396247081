import React from 'react'
import {
    Edit,
    SimpleForm,
    TextInput
} from "react-admin";

const EditCatTitle = ({record}: any) => {
    return <span> Update cat</span>

}

const EditCat = (props: any) => {
    console.log("editc cat ", props)
    return (
        <Edit title={<EditCatTitle/>} {...props}>
            <SimpleForm>
                <TextInput disabled={true} label="Identitet" source="id"/>
                <TextInput required={true} label="Namn" source="name"/>
            </SimpleForm>
        </Edit>)
};


export default EditCat;
