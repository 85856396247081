import React from 'react'
import catService from "../../service/CatService";

const deleteCat = (props: { catId: number, userId: number }): Promise<any> => {
    const {catId, userId} = props;
    return catService.deleteOneCat(catId)
        .then(cat => {
            return {
                data: {
                    id: cat.id
                }
            }
        })
}

export default deleteCat