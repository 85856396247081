import {AdminCatApi, Configuration, CreateCat, FetchParams, RequestContext} from "../generated-sources";
import {Cat} from "../generated-sources/models/Cat";
import {TOKEN} from "../config/Constants";
import createConfiguration from "./TokenHelper";

const BASE_PATH = process.env.REACT_BASEURL ? process.env.REACT_BASEURL : ""

interface Provided<T> {
    data: T,
    total: number
    validUntil?: Date
}

class CatService {

    private api(): AdminCatApi {
        return new AdminCatApi(createConfiguration());
    }

    allCats(): Promise<any> {
        return this.api().allCats().then(cats => {
            const provided: Provided<Array<Cat>> = {
                total: cats.length,
                data: cats
            }
            return provided
        })

    }

    getOneCat(catId: number): Promise<Provided<Cat>> {
        return this.api().oneCat({catid: catId}).then(c => {
            const ret: Provided<Cat> = {
                data: c,
                total: 1
            }
            return ret
        })
    }

    updateOneCat(catId: number, updates: CreateCat): Promise<Cat> {
        return this.api().updateCat({
            catid: catId,
            createCat: updates
        })
    }

    createOneCat(newCat: CreateCat): Promise<Cat> {
        return this.api().createCat({
            createCat: newCat
        });
    }

    deleteOneCat(catId: number): Promise<Cat> {
        return this.api().deleteCat({catid: catId})
    }



}


const catService: CatService = new CatService();
export default catService
