import {CreateCat} from "../../generated-sources/models";
import catService from "../../service/CatService";

export interface CreateOneCatParam {
    userId: number,
    data: any
}

export const createOneCat = (params: CreateOneCatParam): Promise<any> => {
    const {data} = params;
    const createCat: CreateCat = {
        name: data.name
    }
    return catService.createOneCat(createCat).then(c => {
        return {data: c}
    })
}