/* tslint:disable */
/* eslint-disable */
/**
 * Hasseltass API
 * To search for cats from given image.
 *
 * The version of the OpenAPI document: 2.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface UpdatePictureRating
 */
export interface UpdatePictureRating {
    /**
     * 
     * @type {number}
     * @memberof UpdatePictureRating
     */
    rating?: number;
}

export function UpdatePictureRatingFromJSON(json: any): UpdatePictureRating {
    return UpdatePictureRatingFromJSONTyped(json, false);
}

export function UpdatePictureRatingFromJSONTyped(json: any, ignoreDiscriminator: boolean): UpdatePictureRating {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'rating': !exists(json, 'rating') ? undefined : json['rating'],
    };
}

export function UpdatePictureRatingToJSON(value?: UpdatePictureRating | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'rating': value.rating,
    };
}


