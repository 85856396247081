import {TOKEN} from "../config/Constants";
import {Configuration} from "../generated-sources";

const BASE_PATH = process.env.REACT_BASEURL ? process.env.REACT_BASEURL : ""

const createConfiguration = () => {
    const token = localStorage.getItem(TOKEN);
    if (!token) {
        throw new Error("Problem med inloggningen, uppdatera webbläsaren och försök igen!")
    }
    return new Configuration({
        basePath: BASE_PATH,
        accessToken: token,
        headers: {"Authorization": "Bearer " + token}
    })
}


export interface Provided<T> {
    data: T,
    total: number
    validUntil?: Date
}

export default createConfiguration;