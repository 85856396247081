import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from "@material-ui/core/DialogActions";
import IconCancel from "@material-ui/icons/Cancel";
import {Button, useRefresh} from 'react-admin'
import RatingTip from "../components/RatingTip";

interface Props {
    open: boolean;
    onClose: () => void;
}

const RatingHelpDialog = (props: Props) => {
    const refresh = useRefresh();
    return (
        <Dialog {...props} open={props.open} aria-labelledby="form-dialog-title">
            <DialogTitle id="form-dialog-title">Att betygsätta</DialogTitle>
            <DialogContent>
                Betygssättning behövs för att filtrera bort dom bilder som inte blivit riktigt bra.
                <RatingTip/>
            </DialogContent>
            <DialogActions>
                <Button label="ra.action.close" onClick={props.onClose}>
                    <IconCancel/>
                </Button>
            </DialogActions>

        </Dialog>
    );
};

export default RatingHelpDialog
