/* tslint:disable */
/* eslint-disable */
/**
 * Hasseltass API
 * To search for cats from given image.
 *
 * The version of the OpenAPI document: 2.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Pagination,
    PaginationFromJSON,
    PaginationFromJSONTyped,
    PaginationToJSON,
    PictureFilter,
    PictureFilterFromJSON,
    PictureFilterFromJSONTyped,
    PictureFilterToJSON,
    Sort,
    SortFromJSON,
    SortFromJSONTyped,
    SortToJSON,
} from './';

/**
 * 
 * @export
 * @interface PictureParameters
 */
export interface PictureParameters {
    /**
     * 
     * @type {Pagination}
     * @memberof PictureParameters
     */
    pagination?: Pagination;
    /**
     * 
     * @type {Sort}
     * @memberof PictureParameters
     */
    sort?: Sort;
    /**
     * 
     * @type {PictureFilter}
     * @memberof PictureParameters
     */
    filter?: PictureFilter;
}

export function PictureParametersFromJSON(json: any): PictureParameters {
    return PictureParametersFromJSONTyped(json, false);
}

export function PictureParametersFromJSONTyped(json: any, ignoreDiscriminator: boolean): PictureParameters {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'pagination': !exists(json, 'pagination') ? undefined : PaginationFromJSON(json['pagination']),
        'sort': !exists(json, 'sort') ? undefined : SortFromJSON(json['sort']),
        'filter': !exists(json, 'filter') ? undefined : PictureFilterFromJSON(json['filter']),
    };
}

export function PictureParametersToJSON(value?: PictureParameters | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'pagination': PaginationToJSON(value.pagination),
        'sort': SortToJSON(value.sort),
        'filter': PictureFilterToJSON(value.filter),
    };
}


