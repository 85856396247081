/* tslint:disable */
/* eslint-disable */
/**
 * Hasseltass API
 * To search for cats from given image.
 *
 * The version of the OpenAPI document: 2.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    CatAllOf,
    CatAllOfFromJSON,
    CatAllOfFromJSONTyped,
    CatAllOfToJSON,
    CreateCat,
    CreateCatFromJSON,
    CreateCatFromJSONTyped,
    CreateCatToJSON,
} from './';

/**
 * 
 * @export
 * @interface Cat
 */
export interface Cat {
    /**
     * 
     * @type {string}
     * @memberof Cat
     */
    name: string;
    /**
     * 
     * @type {number}
     * @memberof Cat
     */
    id: number;
}

export function CatFromJSON(json: any): Cat {
    return CatFromJSONTyped(json, false);
}

export function CatFromJSONTyped(json: any, ignoreDiscriminator: boolean): Cat {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'name': json['name'],
        'id': json['id'],
    };
}

export function CatToJSON(value?: Cat | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': value.name,
        'id': value.id,
    };
}


